import { Auth } from '@/types'

export const usePortalUrl = (type: Auth.Account) => {
  const config = useRuntimeConfig()

  const sandboxPortalUrl = computed(() =>
    config.public.isProduction
      ? config.public.sandbox.portals[type]
      : config.public.local.portals[type]
  )

  const productionPortalUrl = computed(
    () => config.public.production.portals[type]
  )

  return { sandboxPortalUrl, productionPortalUrl }
}
